import { Link, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { DetailsBlog, Blockquote } from "../../../../src/components/details.js";
import { NextPrevPosts } from "../../../../src/components/blogPost.js";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import arc from "../../../../src/images/blog/syde-1a/arc.png";
import crab from "../../../../src/images/blog/syde-1a/crab.png";
import gunship from "../../../../src/images/blog/syde-1a/gunship.png";
import knightbus from "../../../../src/images/blog/syde-1a/knightbus.png";
import scroll from "../../../../src/images/blog/syde-1a/scrollb.png";
import * as React from 'react';
export default {
  Link,
  graphql,
  AnchorLink,
  DetailsBlog,
  Blockquote,
  NextPrevPosts,
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  arc,
  crab,
  gunship,
  knightbus,
  scroll,
  React
};