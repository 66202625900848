import { Link, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import kpi from "../../../../src/images/kpi.png";
import ron from "../../../../src/images/microsoft/ron.png";
import addie from "../../../../src/images/microsoft/addie.png";
import { Details, Blockquote } from "../../../../src/components/details.js";
import MoreProjects from "../../../../src/components/moreProjects.js";
import * as React from 'react';
export default {
  Link,
  graphql,
  AnchorLink,
  kpi,
  ron,
  addie,
  Details,
  Blockquote,
  MoreProjects,
  React
};