import { Link, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { DetailsBlog, Blockquote } from "../../../../src/components/details.js";
import { NextPrevPosts } from "../../../../src/components/blogPost.js";
import thumbnail from "../../../../src/images/blog/microsoft-2019/thumbnail.png";
import * as React from 'react';
export default {
  Link,
  graphql,
  AnchorLink,
  DetailsBlog,
  Blockquote,
  NextPrevPosts,
  thumbnail,
  React
};