import { Link, graphql } from "gatsby";
import { Details, Blockquote } from "../../../../src/components/details.js";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import MoreProjects from "../../../../src/components/moreProjects.js";
import round from "../../../../src/images/unity/round-button.png";
import blurred from "../../../../src/images/unity/blurred-bg.png";
import glare from "../../../../src/images/unity/glare-animation.gif";
import activate from "../../../../src/images/unity/activate.gif";
import popbubble from "../../../../src/images/unity/popbubble.gif";
import sliderblock from "../../../../src/images/unity/sliderblock.gif";
import * as React from 'react';
export default {
  Link,
  graphql,
  Details,
  Blockquote,
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  MoreProjects,
  round,
  blurred,
  glare,
  activate,
  popbubble,
  sliderblock,
  React
};