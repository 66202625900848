import { Link, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Details, Blockquote } from "../../../../src/components/details.js";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import MoreProjects from "../../../../src/components/moreProjects.js";
import table from "../../../../src/images/covid-data/table.png";
import alberta from "../../../../src/images/covid-data/alberta.png";
import uk from "../../../../src/images/covid-data/uk.png";
import worldometer from "../../../../src/images/covid-data/worldometer.png";
import toggle from "../../../../src/images/covid-data/toggle.png";
import * as React from 'react';
export default {
  Link,
  graphql,
  AnchorLink,
  Details,
  Blockquote,
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  MoreProjects,
  table,
  alberta,
  uk,
  worldometer,
  toggle,
  React
};