import { Link, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import kpi from "../../../../src/images/kpi.png";
import callToAction from "../../../../src/images/sorenewals/calltoactions.png";
import redundancy from "../../../../src/images/sorenewals/redundancy.png";
import oneWindow from "../../../../src/images/sorenewals/onewindow.png";
import simpleQuestions from "../../../../src/images/sorenewals/simplequestions.png";
import errors from "../../../../src/images/sorenewals/errors.png";
import error1 from "../../../../src/images/sorenewals/error1.png";
import error2 from "../../../../src/images/sorenewals/error2.png";
import users from "../../../../src/images/sorenewals/users2.png";
import users2 from "../../../../src/images/sorenewals/users3.png";
import users3 from "../../../../src/images/sorenewals/users4.png";
import userJourney from "../../../../src/images/sorenewals/userjourney.png";
import sketches from "../../../../src/images/sorenewals/sketches.png";
import flow from "../../../../src/images/sorenewals/flow.png";
import lofi from "../../../../src/images/sorenewals/lofi2.png";
import system from "../../../../src/images/sorenewals/system.png";
import Emily from "../../../../src/images/sorenewals/emilytesting.png";
import { Details, Blockquote } from "../../../../src/components/details.js";
import MoreProjects from "../../../../src/components/moreProjects.js";
import * as React from 'react';
export default {
  Link,
  graphql,
  AnchorLink,
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  kpi,
  callToAction,
  redundancy,
  oneWindow,
  simpleQuestions,
  errors,
  error1,
  error2,
  users,
  users2,
  users3,
  userJourney,
  sketches,
  flow,
  lofi,
  system,
  Emily,
  Details,
  Blockquote,
  MoreProjects,
  React
};