// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-microsoft-2019-mdx": () => import("./../../../src/pages/blog/microsoft-2019.mdx" /* webpackChunkName: "component---src-pages-blog-microsoft-2019-mdx" */),
  "component---src-pages-blog-syde-1-a-mdx": () => import("./../../../src/pages/blog/syde-1a.mdx" /* webpackChunkName: "component---src-pages-blog-syde-1-a-mdx" */),
  "component---src-pages-covid-data-mdx": () => import("./../../../src/pages/covid-data.mdx" /* webpackChunkName: "component---src-pages-covid-data-mdx" */),
  "component---src-pages-covid-main-mdx": () => import("./../../../src/pages/covid-main.mdx" /* webpackChunkName: "component---src-pages-covid-main-mdx" */),
  "component---src-pages-figma-mdx": () => import("./../../../src/pages/figma.mdx" /* webpackChunkName: "component---src-pages-figma-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-microsoft-mdx": () => import("./../../../src/pages/microsoft.mdx" /* webpackChunkName: "component---src-pages-microsoft-mdx" */),
  "component---src-pages-otherprojects-mdx": () => import("./../../../src/pages/otherprojects.mdx" /* webpackChunkName: "component---src-pages-otherprojects-mdx" */),
  "component---src-pages-sixdegrees-mdx": () => import("./../../../src/pages/sixdegrees.mdx" /* webpackChunkName: "component---src-pages-sixdegrees-mdx" */),
  "component---src-pages-sorenewals-mdx": () => import("./../../../src/pages/sorenewals.mdx" /* webpackChunkName: "component---src-pages-sorenewals-mdx" */),
  "component---src-pages-two-handed-xri-mdx": () => import("./../../../src/pages/two-handed-xri.mdx" /* webpackChunkName: "component---src-pages-two-handed-xri-mdx" */),
  "component---src-pages-unity-mdx": () => import("./../../../src/pages/unity.mdx" /* webpackChunkName: "component---src-pages-unity-mdx" */)
}

