import { Link, graphql } from "gatsby";
import { DetailsProject, Blockquote } from "../../../../src/components/details.js";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import MoreProjects from "../../../../src/components/moreProjects.js";
import * as React from 'react';
export default {
  Link,
  graphql,
  DetailsProject,
  Blockquote,
  AnchorLink,
  MoreProjects,
  React
};