export const palette = {
  headingBlack: "#333333",
  black: "#1a1a1a",
  blackBlue: "#2d3748",
  darkGrey: "#424a57",
  grey: "#737380",
  blueGrey: "#667083",
  lightGrey: "#a7b0be",
  lightGreyBlue: "#cbd5e0",
  white: "#fdfdfd",
  purple: "#c989df",
  darkThemeBg: "#1b1b23",
  highlight: "#e3c4f8c0",
}

export const font = {
  ibmPlex: '"IBM Plex Sans", sans-serif',
  openSans: '"Open Sans", sans-serif',
}

export const other = {
  headerFontWeight: 700,
  borderRadius: "8px",
}

export const media = {
  medium: "(max-width: 900px)",
  small: "(max-width: 520px)",
}
