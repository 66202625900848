import { Link, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Details, Blockquote } from "../../../../src/components/details.js";
import MoreProjects from "../../../../src/components/moreProjects.js";
import kpi from "../../../../src/images/kpi.png";
import initial from "../../../../src/images/covid-main/initial.png";
import * as React from 'react';
export default {
  Link,
  graphql,
  AnchorLink,
  Details,
  Blockquote,
  MoreProjects,
  kpi,
  initial,
  React
};