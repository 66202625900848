import { Link, graphql } from "gatsby";
import { Details, Blockquote } from "../../../../src/components/details.js";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import MoreProjects from "../../../../src/components/moreProjects.js";
import snap from "../../../../src/images/figma/before-after-snap.gif";
import slick from "../../../../src/images/figma/slick-animation.gif";
import * as React from 'react';
export default {
  Link,
  graphql,
  Details,
  Blockquote,
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  MoreProjects,
  snap,
  slick,
  React
};